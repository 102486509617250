<template>
    <div class="attachments mt-10 mb-5">
        <h2 class="mb-10">{{ $t("attachments.title") }}
            <v-btn
                class="mx-2"
                color="secondary"
                fab
                small
                @click.prevent="dialog = true"
            >
                <v-icon>
                    mdi-upload
                </v-icon>
            </v-btn>
        </h2>

        <v-row v-if="loading">
            <v-col cols="12" md="4">
                <v-skeleton-loader
                    type="card-heading"
                ></v-skeleton-loader>
            </v-col>
        </v-row>

        <v-row v-else>
            <v-col v-for="attachment in attachments" :key="attachment.attachment" cols="12" md="3">
                <v-card>
                    <v-card-actions>
                        <v-list-item class="grow" style="width: 100%">
                            <v-row no-gutters>
                                <v-col cols="7">
                                    {{ truncate(attachment.name) }}
                                </v-col>
                                <v-col cols="5">
                                    <v-btn color="secondary" text
                                           @click="viewAttachment(attachment.name, attachment.uuid)">
                                        <v-icon>mdi-eye</v-icon>
                                    </v-btn>
                                    <v-btn
                                        class="ml-1"
                                        color="error"
                                        text
                                        @click="deleteAttachment(attachment.attachment)"
                                    >
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-list-item>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>


        <v-dialog
            v-model="dialog"
            max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ $t("attachments.upload") }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <file-upload accept="*/*" v-on:file="filesChange"></file-upload>


                        <v-simple-table v-show="uploadedFiles.length > 0" class="mt-3">
                            <template v-slot:default>
                                <tbody>
                                <tr
                                    v-for="file in uploadedFiles"
                                    :key="file.uuid"
                                >
                                    <td>{{ truncate(file.name) }}</td>
                                    <td style="width: 94px;">
                                        <v-progress-linear
                                            :color="file.color"
                                            :indeterminate="file.indeterminate"
                                            height="6"
                                            rounded
                                            value="100"

                                        ></v-progress-linear>
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="secondary"
                        text
                        @click="dialog = false"
                    >
                        {{ $t("attachments.close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="view_dialog"
            max-width="1000"
        >
            <v-card>
                <v-card-title class="text-h5">
                    <v-btn :to="'/documents/'+view.document" class="mr-5" icon>
                        <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>
                    {{ view.name }}
                </v-card-title>
                <v-card-text>
                    <DocumentViewer :uuid="view.document"></DocumentViewer>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// https://www.digitalocean.com/community/tutorials/how-to-handle-file-uploads-in-vue-2

import Accounting from "../helpers/Accounting";
import { v4 as uuidv4 } from "uuid";
import Utils from "../helpers/Utils";
import FileUpload from "@/components/FileUpload.vue";
import DocumentViewer from "@/components/DocumentViewer.vue";

export default {
    name: "Attachments",
    components: {DocumentViewer, FileUpload},
    props: ["element"],
    mounted() {
        this.loadAttachments();
    },
    data() {
        return {
            dialog: false,
            uploadedFiles: [],
            loading: false,
            attachments: [],
            view_dialog: false,
            view: {
                name: null,
                document: null
            }
        };
    },
    watch: {
        dialog: {
            handler() {
                if (!this.dialog) {
                    this.uploadedFiles = [];
                }
            }
        }
    },
    methods: {
        truncate(str) {
            const MAX_LENGTH = 24;
            if (str.length <= MAX_LENGTH) {
                return str;
            }

            return str.substring(0, MAX_LENGTH) + "...";
        },
        filesChange(file) {
            let requestUUID = uuidv4();

            this.uploadedFiles.push({
                uuid: requestUUID,
                name: file.name,
                color: "primary",
                indeterminate: true
            });

            this.upload(requestUUID, file);
        },
        upload(requestUUID, file) {
            return new Promise(() => {
                let formData = new FormData();
                formData.append("file", file);

                let key = -1;
                for (let i = 0; i < this.uploadedFiles.length; i++) {
                    if (this.uploadedFiles[i].uuid === requestUUID) {
                        key = i;
                    }
                }

                formData.append("name", file.name);

                // Upload file
                Accounting.upload("/documents", formData)
                    .then((response) => {
                        let uuid = response.data.uuid;
                        this.uploadedFiles[key].color = "green";

                        Accounting.post("/attachments", {
                            attached_to: this.element,
                            document: uuid
                        }).then(() => {
                            this.uploadedFiles[key].indeterminate = false;
                            this.loadAttachments();
                        }).catch(() => {
                            this.uploadedFiles[key].color = "red";
                            this.uploadedFiles[key].indeterminate = false;
                        });

                    })
                    .catch(() => {
                        this.uploadedFiles[key].color = "red";
                        this.uploadedFiles[key].indeterminate = false;
                    });
            });
        },
        loadAttachments() {
            this.loading = true;
            Accounting.get("/attachments/resource/" + this.element)
                .then(async (response) => {
                    let attachments = response.data.attachments;
                    let documents = [];

                    for (let i = 0; i < attachments.length; i++) {
                        const element = attachments[i];
                        if (this.$ls.get("cache_" + element.document) == null) {
                            let doc = await Accounting.get("/documents/" + element.document)
                                .catch(() => {
                                });
                            let attch = doc.data;
                            attch.attachment = element.uuid;
                            documents.push(attch);
                            this.$ls.set("cache_" + element.document, attch, 60 * 60 * 1000 * 24 * 90);
                        } else {
                            documents.push(this.$ls.get("cache_" + element.document));
                        }
                    }

                    this.attachments = documents;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        deleteAttachment(uuid) {
            this.$swal({
                title: this.$t("attachments.delete_title"),
                text: this.$t("attachments.delete_text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("attachments.delete_yes"),
                cancelButtonText: this.$t("attachments.delete_no"),
                confirmButtonColor: Utils.getColor(this, "error"),
                cancelButtonColor: Utils.getColor(this, "primary")
            }).then(result => {
                if (result.isConfirmed) {
                    this.loading = true;
                    Accounting.delete("/attachments/" + uuid).then(() => {
                        this.loadAttachments();
                    }).catch(() => {
                        this.loading = false;
                    });
                }
            });
        },
        viewAttachment(name, uuid) {
            this.view = {
                name: name,
                document: uuid
            };
            this.view_dialog = true;
        }
    }
};
</script>

<style scoped>

.app--dark .dropbox {
    outline: 2px dashed #d0d0d0;
    background: #363636;
    color: #d0d0d0;
}

.app--dark .dropbox:hover {
    background: #505050;
}

.dropbox {
    outline: 2px dashed #363636; /* the dash box */
    outline-offset: -10px;
    background: #f6f6f6;
    color: #363636;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
    border-radius: 4px;
}

.input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
}

.dropbox:hover {
    background: #e0e0e0;
}

.dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
}
</style>