<template>
    <div>
        <object v-if="mimetype === 'application/pdf' && !loading && !virus" :data="object" :type="mimetype"
                class="document_preview"></object>
        <img v-else-if="mimetype === 'image/jpeg' || mimetype === 'image/png' && !loading && !virus" :alt="name"
             :src="object"
             class="image"/>
        <v-alert
            v-else
            border="top"
            colored-border
            elevation="2"
            type="warning"
        >
            {{ $t("document.no_preview") }} <br>
            <slot></slot>
        </v-alert>

        <v-alert
            v-if="virus"
            border="top"
            colored-border
            elevation="2"
            type="error"
        >
            {{ $t("document.virus") }} <br>
        </v-alert>

        <v-overlay
            :absolute="true"
            :opacity="0.9"
            :value="loading"
        >
            <v-progress-circular
                color="purple"
                indeterminate
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import Accounting from "@/helpers/Accounting";

export default {
    name: "DocumentViewer",
    props: ["uuid"],
    data() {
        return {
            loading: false,
            mimetype: null,
            object: null,
            virus: false
        };
    },
    watch: {
        uuid: function (val) {
            this.fetchDocument(val);
        }
    },
    methods: {
        fetchDocument(uuid) {
            if (uuid === undefined || uuid === null) {
                return;
            }

            this.loading = true;
            this.mimetype = null;
            this.object = null;
            Accounting.get("/documents/" + uuid)
                .then(response => {
                    const data = response.data;
                    this.mimetype = data.mimetype;

                    for (const tag of data.tags) {
                        if (tag.key === "virus") {
                            this.virus = true;
                        }
                    }

                    if (!this.virus) {
                        Accounting.blob("/documents/" + uuid + "/view")
                            .then(blob => {
                                this.object = URL.createObjectURL(blob);
                                this.loading = false;
                            }).catch(error => {
                            if (error.response) {
                                let errors = [];
                                for (const errorElement of error.response.data.errors) {
                                    errors.push({
                                        type: "error",
                                        text: errorElement
                                    });
                                }
                                this.$store.commit("SET_ALERTS", errors);
                            } else if (error.request) {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: this.$t("api.no_response")
                                }]);
                            } else {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: error.message
                                }]);
                            }
                            this.loading = false;
                        });
                    } else {
                        this.loading = false;
                    }
                }).catch(error => {
                if (error.response) {
                    let errors = [];
                    for (const errorElement of error.response.data.errors) {
                        errors.push({
                            type: "error",
                            text: errorElement
                        });
                    }
                    this.$store.commit("SET_ALERTS", errors);
                } else if (error.request) {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: this.$t("api.no_response")
                    }]);
                } else {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: error.message
                    }]);
                }
                this.loading = false;
            });
        }
    },
    mounted() {
        this.fetchDocument(this.uuid);
    }
};
</script>

<style scoped>
.document_preview {
    width: 100%;
    height: 850px;
}

.image {
    max-width: 100%;
}
</style>